@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
html {
  font-size: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* overflow: hidden; */
}

*,
*::before,
*::after {
  scroll-behavior: smooth;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f0f0f0;

  line-height: 1.3;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  font-family: 'Inter', sans-serif;
}

.center-items {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.full-center-items {
  display: grid;
  justify-content: center;
  align-items: center;
}

.login,
.register {
  height: 100vh;
  /* height: calc(var(--vh, 1vh) * 100); */
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #121212;
  color: white;
}
@media only screen and (max-width: 500px) {
  .login,
  .register {
    height: calc(var(--vh, 1vh) * 100);
  }
}
/* 
.login .form-group input,
.register .form-group input {
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  padding: 10px;
  font-size: 1.4rem;
  margin: 10px;
  color: inherit;
} */
/* 
.login .btn-submit,
.login button,
.register .btn-submit,
.register button {
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  color: inherit;
  padding: 10px;
  font-size: 1.4rem;
  margin: 10px;
}

.login .btn-submit:active,
.login button:active,
.register .btn-submit:active,
.register button:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.login .btn-submit a,
.login button a,
.register .btn-submit a,
.register button a {
  color: inherit;
  text-decoration: none;
} */
/* Login */
.login {
  background-image: url('https://images.pexels.com/photos/5708064/pexels-photo-5708064.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
}
.login .logo {
  position: absolute;
  top: 1em;
  left: 1em;
}
.login .logo img {
  width: 200px;
  height: auto;
}
.login .login-container {
  height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
  width: 60vw;
  padding: 2em;
  background: rgba(255, 255, 255, 0.651);
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  border-radius: 30px;
  backdrop-filter: blur(9.5px);
  justify-content: center;
  /* align-items: center; */
}
.login .register-container {
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  width: 60vw;
  padding: 2em;
  background: rgba(255, 255, 255, 0.651);
  display: grid;
  grid-template-rows: 1fr 8fr 1fr;
  border-radius: 30px;
  backdrop-filter: blur(9.5px);
  justify-content: center;
}

.login .login-container h1 {
  font-size: 2em;
  font-weight: 800;
  color: black;
}
.login .form-container {
  display: grid;
  /* justify-content: center; */
  grid-template-rows: 1fr 1fr 0.7fr;
  width: 45vw;
}
.login .register-container .form-container {
  grid-template-rows: 1fr 1fr 1fr 1fr 0.7fr;
}
@media only screen and (max-width: 500px) {
  .login .register-container,
  .login .login-container {
    width: 90vw;
  }
  .login .form-container {
    width: 78vw;
  }
}

.dashboard-app {
  display: -ms-grid;
  display: grid;
  height: 100vh;
  overflow: hidden;
  /* -ms-grid-columns: (1fr)[6]; */
  grid-template-columns: repeat(6, 1fr);
  /* -ms-grid-rows: (1fr)[10]; */
  grid-template-rows: 60px repeat(11, 1fr);
}
.dashboard-app a {
  text-decoration: none;
  color: black;
}
@media only screen and (max-width: 500px) {
  .dashboard-app {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.dashboard-app .nav-bar {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 10;
  grid-row: 1/13;
  background-color: #282f32;
  background: white;
  border-right: 1px solid #9c9ab8;
  display: -ms-grid;
  display: grid;
  /* -ms-grid-rows: (1fr)[10]; */
  grid-template-rows: repeat(10, 1fr);
}

.dashboard-app .nav-bar .logo {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
  padding: 1em 0;
  transform: scale(0.9);
}
.dashboard-app .nav-bar .logo .logo-area {
  /* background-color: #ffdc46; */
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 80%;
  /* border: 1px solid black; */
  margin: 0 auto;
  /* border-radius: 10px; */
}

.dashboard-app .nav-bar .nav-btn {
  -ms-grid-row: 3;
  -ms-grid-row-span: 6;
  grid-row: 3/9;
}

.dashboard-app .nav-bar .nav-btn ul {
  height: 100%;
  list-style: none;
  display: -ms-grid;
  display: grid;
  /* -ms-grid-rows: (1fr)[6]; */
  grid-template-rows: repeat(6, 1fr);
}
.input-adornment {
  /* color: blue; */
  color: #6f6f79;
  font-size: 16px;
}

.dashboard-app .nav-bar .nav-btn ul li {
  padding: 10px 0;
}

.dashboard-app .nav-bar .nav-btn ul li .one-btn {
  height: 100%;
  display: -ms-grid;
  display: grid;
  font-size: 13px;
  color: #9c9c9c;
}
.dashboard-app .nav-bar .nav-btn ul li .one-btn a {
  /* padding: 8px; */
  grid-column: 1/3;
  display: grid;
  margin-left: 5%;
  font-weight: 600;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-right: 5%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -ms-grid-columns: 1fr 3fr;
  grid-template-columns: 1fr 3fr;
  text-decoration: none;
  color: #9c9c9c;
  /* font-weight: 800; */
  font-size: 13px;
  justify-content: center;
}

.dashboard-app .nav-bar .nav-btn ul li .one-btn .btn-name {
  letter-spacing: 0.8px;
  justify-content: left;
}

.dashboard-app .nav-bar .nav-btn ul li .one-btn .icon {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dashboard-app .nav-bar .nav-btn ul li .one-btn .iconify {
  font-size: 22px;
}
.dashboard-app .nav-bar .nav-btn ul li .one-btn a:hover {
  color: black;
  background: #efefef;
}

.dashboard-app .nav-bar .nav-btn ul li .active-btn {
  /* color: white; */
  font-weight: 600;
  background-color: #0167ff;
}
.dashboard-app .nav-bar .nav-btn ul li .one-btn .active-btn:hover {
  background-color: #0057da;
}
.dashboard-app .nav-bar .nav-btn ul li .active-btn .icon,
.dashboard-app .nav-bar .nav-btn ul li .active-btn .btn-name {
  color: white;
}

.dashboard-app .nav-bar .profile-small {
  -ms-grid-row: 9;
  -ms-grid-row-span: 2;
  grid-row: 9/11;
  width: 100%;
  color: white;
  /* justify-content: center; */
}
#profile-card {
  display: flex;
  grid-template-columns: auto auto;
  align-self: center;
  margin: auto;
  width: 80%;
  background: white;
  border-radius: 12px;
  padding: 0.5rem;
  transition: 0.3s;
}

/* #profile-card:hover {
  box-shadow: none;
  cursor: pointer;
  transform: translateY(2px);
} */

#profile-card:hover > #profile-photo {
  opacity: 1;
}

#profile-photo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  align-self: center;
  box-shadow: 0 6px 30px rgba(199, 199, 199, 0.5);
  opacity: 0.9;
  transition: 0.3s;
}

.text-wrapper {
  font-family: 'Poppins', sans-serif;
  /* line-height: 0; */
  align-self: center;
  margin-left: 10px;
  color: black;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.text-wrapper p {
  margin: 0;
}

.contact-wrapper {
  display: block;
  text-decoration: underline;
  font-size: 10px;
}
#name {
  font-size: 14px;
}

.dashboard-app .greet {
  background-color: white;
  border-bottom: 1px solid #9c9ab8;
  padding-left: 30px;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 2/5;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  font-weight: 300;
  font-size: 20px;
}

.dashboard-app .app-body {
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-column: 2/5;
  -ms-grid-row: 2;
  -ms-grid-row-span: 9;
  grid-row: 1/13;
  display: -ms-grid;
  display: grid;
  /* -ms-grid-rows: (1fr)[9]; */
  grid-template-rows: 60px repeat(11, 1fr);
}
.dashboard-app .app-body .app-form {
  grid-row: 1/13;
  display: grid;
  grid-template-rows: 60px repeat(11, 1fr);
  /* background-color: red; */
}

.dashboard-app .app-body .app-form .title {
  grid-row: 1/2;
  background-color: white;
  /* width: 100%; */
  padding: 0 0 0 30px;
  font-size: 18px;
  font-weight: 300;
  border-bottom: 1px solid #9c9ab8;
  -ms-grid-columns: 1fr 200px;
  grid-template-columns: 1fr 200px;
}

.dashboard-app .app-body .title .save-btn {
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
}
.dashboard-app .app-body .title .save-btn .btn-style {
  padding-left: 1rem;
  padding-right: 1rem;
}
.dashboard-app .app-body .data-all {
  padding: 30px;
  -ms-grid-row: 2;
  -ms-grid-row-span: 8;
  grid-row: 2/13;
  overflow-y: auto;
}

/* Appearance */
.dashboard-app .app-body .data-all .data-select {
  background-color: white;
  /* height: 300px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0.5em;
  /* grid-column: 1/4; */
}

.tile {
  position: relative;
  border: 1px solid #eee;
  padding: 5px;
  margin: 0.5rem;
  border-radius: 7px;
  height: 200px;
  background-color: #f4f4f4;
  min-height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.tile:hover {
  border-color: #6f6f79;
  background-color: #e5e5e5;
}

.tile.selected {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /* border: 2px solid #333; */
}

.tile.selected .on {
  display: block !important;
}

.tile.selected .off {
  display: none !important;
}

.tile-wrapper {
  padding: 0.5rem;
  margin: 0;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
}

#appearance-id-1 {
  background-color: #b5bac0;
}

#appearance-id-2 {
  background-color: #3b923b;
}

#appearance-id-3 {
  background-color: #4178be;
}

#appearance-id-4 {
  background-color: #bc64d6;
}

#appearance-id-5 {
  background-color: #0dac9e;
}

#appearance-id-6 {
  background-color: #4178be;
}

.tile-header {
  color: #4178be;
  font-size: 18px;
  text-align: center;
}

.indicators {
  position: absolute;
  top: 5px;
  right: 5px;
}

.indicators .on {
  display: none;
}

.indicators .off {
  display: block;
  color: red;
}
/* Settings */
.dashboard-app .data-settings {
  background-color: white;
  min-height: 100%;
  display: grid;
  grid-template-rows: 0.8fr 1fr 0.4fr;
}
.data-settings .profile-display {
  padding: 1em;
  justify-content: center;
  display: grid;
  /* grid-template-rows: auto 1.5fr 0.8fr; */
  grid-template-rows: 1fr auto auto;
}
.profile-image {
  justify-content: center;
}
.profile-display .profile-image .image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* margin: 0 auto; */
  background-image: url('https://media-exp3.licdn.com/dms/image/C5603AQEPVyNlEWHRVg/profile-displayphoto-shrink_400_400/0/1607192847662?e=1629936000&v=beta&t=tqGfDGUApApp9miDzQPe1wb0R48kWO25Ef9wzN-k-Oc');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #25b3fb;
}
.profile-display .profile-name {
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.profile-name p,
.profile-location p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-display .profile-location {
  font-size: 12px;
  justify-content: center;
  color: #666666;
}
.data-settings .edit-details {
  margin: 0.5em 1em;
  background-color: #c0e8ff7c;
  /* background-color: #494949; */
  /* border: 1px solid #494949; */
  display: grid;
  grid-template-rows: 1fr 4fr;
}
.edit-details .details-title {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding: 0 0 0 1em;
}
.details-title .save-details {
  justify-content: right;
}
.save-details .save-detail-btn {
  /* padding: 4px; */
  font-size: 10px;
  background-color: #00aaff;
  border: none;
  font-size: 14px;
  padding: 7px 12px;
  /* margin-right: 10px; */
  border-radius: 5px;
  color: white;
}
.details-form-container {
  height: 100%;
  padding: 0.5em 1em;
}
.details-form-container .edit-name {
  padding: 0;
  height: 20px;
  width: 80%;
}
.edit-details .details-form {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.delete-account-container {
  margin: 0.5em 1em;
  margin-bottom: 1em;
  /* height: 100%; */
  background-color: rgba(255, 0, 0, 0.123);
  padding: 0.5em 1em;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 0.6fr;
}
.delete-account-container .delete-account-title {
  font-weight: 400;
}
.delete-account-container .delete-account-btn {
  justify-content: right;
  grid-row: 1/3;
  grid-column: 2/3;
  /* padding: 0.5em 1em; */
}
.delete-account-container .delete-account-sub {
  font-size: 10px;
  color: rgb(32, 32, 32);
  /* color:tomato */
  /* grid-column: 1/3; */
}

/* .dashboard-app .app-body .data-all .data-settings {
  background-color: white;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}
.data-settings > div {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 3.5fr;
}

.data-settings > div > input,
.data-settings > div > span {
  padding: 0.5rem;
  font-size: 14px;
}
.data-settings > div > input {
  width: 80%;
  border: none;
  border-bottom: 1px solid #b5bac0;
}
.data-settings > div > input:focus {
  outline: none;
  border-color: black;
}
.data-settings > div > span {
  font-weight: 500;
} */
/* Custom Links */

.data-custom-links .add-custom-link-container {
  background-color: white;
  padding: 1em;
  display: grid;
  /* border-radius: 5px; */

  grid-template-rows: 1fr 1fr 1fr;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.add-custom-link-container .add-btn {
  justify-content: center;
}
.add-custom-link-container .custom-url-title,
.add-custom-link-container .custom-url-link {
  padding: 0.5em 1em;
}
.data-custom-links .disp-custom .single-custom-link {
  padding: 1em;
  background-color: white;
  margin-top: 1em;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.single-custom-link > div {
  /* background-color: #25b3fb; */
  font-size: 15px;
  /* font-weight: 300; */
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.single-custom-link .link-title {
  font-weight: 500;
}
.link-title p,
.link-url p {
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.single-custom-link .link-url {
  font-size: 12px;
  font-weight: 300;
}
.single-custom-link .del-btn {
  justify-content: center;
  grid-row: 1/3;
  grid-column: 2/3;
}
/* Support */
.data-support {
  background-color: white;
  padding: 2rem;
}
.data-support .query {
  /* padding: 1rem; */
  margin-bottom: 1rem;
}
.data-support .support-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 1rem 0;
  text-decoration: underline;
}
.data-support .body {
  line-height: 2;
  font-weight: 400;
  font-size: 14px;
}
.data-support .body .payment-qr-code {
  width: 300px;
  height: auto;
  margin: 1em;
}
.data-support #share-email {
  margin-left: 1rem;
}
.data-support #share-email i {
  margin-right: 1rem;
}

.dashboard-app .disp-title {
  background-color: white;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  -ms-grid-column: 5;
  -ms-grid-column-span: 2;
  grid-column: 5/7;
  /* padding-left: 30px; */
  font-weight: 500;
  font-size: 14px;
  display: -ms-grid;
  display: grid;
  border-left: 1px solid #9c9ab8;
  border-bottom: 1px solid #9c9ab8;
  grid-template-columns: 1fr auto;
}

.dashboard-app .disp-title .url-btn {
  display: -ms-grid;
  display: grid;

  /* -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr; */
}
.dashboard-app .disp-title a {
  width: 100%;
  display: grid;
  align-items: center;

  padding: 0;
  margin: 0;
}
.dashboard-app .disp-title .block-url {
  font-weight: 300;
  font-size: 10px;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
}
.disp-title .title-link {
  display: grid;
  grid-template-columns: auto 1fr;
}
.disp-title .title-link > p {
  padding: 0 0.3em 0 1em;
  font-size: 14px;
}
.qr-code-container {
  grid-row: 2/13;
  grid-column: 5/7;
  background: none;
  color: white;
  justify-content: center;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  z-index: 1;
}
.qr-code-container .qr-code-bg {
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.5;
}
.qr-code-container .qr-code {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.qr-code canvas {
  color: black;
}

.dashboard-app .app-disp {
  border-left: 1px solid #9c9ab8;
  -ms-grid-column: 5;
  -ms-grid-column-span: 2;
  grid-column: 5/7;
  -ms-grid-row: 2;
  -ms-grid-row-span: 9;
  grid-row: 2/13;
  display: -ms-grid;
  display: grid;
  /* -ms-grid-rows: (1fr)[9]; */
  grid-template-rows: repeat(9, 1fr);
}

.dashboard-app .app-disp .mobile-disp-container {
  -ms-grid-row: 1;
  -ms-grid-row-span: 9;
  grid-row: 1/10;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.dashboard-app .app-disp .mobile-disp-container .mobile-disp,
.dashboard-app .app-disp .mobile-disp-container iframe {
  height: 812px;
  width: 375px;
  position: absolute;
  border-radius: 35px;
  background-color: none;
  /* overflow: scroll; */
  left: 50%;
  /* -webkit-transform: translateX(-50%); */
  transform: translateX(-50%) scale(0.6);
  /* transform: ; */
}

.dashboard-app .app-disp .mobile-disp-container .mobile-disp {
  border: 18px solid black;
  height: 847px;
  width: 407px;
  border-radius: 55px;
  /* z-index: 1;
  background: none; */
}

/* .dashboard-app .app-disp .mobile-disp-container iframe {
  z-index: -1;
} */

.dashboard-app .single-social-link {
  width: 100%;
  height: 85px;
  background-color: white;
  /* border-radius: 10px; */
  /* margin-bottom: 30px; */
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.1fr 8fr;
  grid-template-columns: 2fr 10fr;
}

.dashboard-app .single-social-link .icon {
  font-size: 35px;
  color: #666666;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dashboard-app .single-social-link .input-link {
  /* background-color: none;
  border: none;
  font-family: inherit;
  font-weight: 400; */
  /* width: 90%;
  border-bottom: 1px solid #666666;
  color: #666666;
  padding: 4px 5px;
  transition: 0.2s all; */
  padding: 0 1em 0 0;
}

/* .dashboard-app .single-social-link input:focus {
  color: black;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
} */

.dashboard-app .btn-style {
  border-radius: 4px;
  border: none;
  padding: 0.4rem 0.5rem;
  font-family: inherit;
  font-size: 1rem;
  margin: 0 0.5rem;
  /* border: solid 2px transparent; */
  background-color: #25b3fb;
  /* background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#fc30b6),
      to(#fe6f4e)
    ),
    -webkit-gradient(linear, left top, right top, from(#fc30b6), to(#fe6f4e));
  background-image: linear-gradient(to right, #fc30b6, #fe6f4e),
    linear-gradient(to right, #fc30b6, #fe6f4e); */
  /* background-origin: border-box;
  background-clip: content-box, border-box;
  -webkit-box-shadow: 2px 1000px 1px #fff inset;
  box-shadow: 2px 1000px 1px #fff inset; */
}

.dashboard-app .btn-style p {
  font-size: 14px;
  font-weight: 800;
  padding: 0;
  margin: 0;
  /* background-color: #25b3fb; */
  color: white;
}

.dashboard-app .btn-style:active {
  transform: scale(0.9);
}

/* .dashboard-app .btn-style:hover p {
  background: -webkit-linear-gradient(white, white);
  /* -webkit-background-clip: text; */
/* -webkit-text-fill-color: transparent; */
/* } */

/* Display Block */

.user-block {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: #ffffff; */
  /* color: white; */
  /* background-image: linear-gradient(180deg, #fc30b7, #fe6f4d); */
}

.user-block .profile-container {
  /* overflow: scroll; */
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 0;
  padding-top: 2em;
  display: grid;
  grid-template-rows: auto auto 1fr 60px;
}
.user-block .user-data {
  min-height: 30vh;
  min-height: calc(var(--vh, 1vh) * 30);
  /* background-color: #ffffff42; */
  display: grid;
  grid-template-rows: 1fr auto auto;
  padding-bottom: 0.5em;
}
.user-data .user-avatar {
  justify-content: center;
}
.user-data .user-avatar .avatar-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* width: */
  /* background: red; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.user-data .user-name {
  /* line-height: 2; */
  padding: 0.3em;
  font-size: 25px;
  font-weight: 600;
  justify-content: center;
}
.user-data .user-name p,
.user-data .user-location p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-data .user-location {
  /* line-height: 2; */
  justify-content: center;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-location .iconify {
  font-size: 15px;
  margin-right: 0.3em;
}
.user-location > div {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.user-block .user-social-links {
  /* padding: 1em 2em; */
  display: grid;
  justify-content: center;
  /* grid-template-columns: repeat(5, 1fr); */
}
.user-block .user-social-links > div {
  /* background-color: #ffffff42; */
  width: auto;
  border-radius: 10px;
  text-align: center;
  margin: 0.5em 1em;
  padding-bottom: 0.5em;
}
.user-block .user-social-links a,
.user-block .user-social-links a:active,
.user-custom-links a,
.user-custom-links a:active {
  color: inherit;
  text-decoration: none;
}

.user-social-links .iconify {
  /* justify-content: center; */
  margin: 0.5em 0.5em 0 0.5em;
  /* align-items: center; */
  font-size: 2em;
  /* padding: 1em; */
}
.user-social-links > div > a:hover {
  /* font-size: 1.1em;
   */
  /* background-color: white; */
  /* color: red;
   */
  opacity: 0.7;
  /* transform: scale(1.1); */
}

.user-block .user-custom-links {
  padding: 0.5em 1em;
}

.user-custom-links .custom-btn {
  /* background-color: #00e1ff; */
  border: 2px solid;
  background: none;
  border-radius: 10px;
  font-weight: 500;
  padding: 0.8em 1em;
  font-size: 20px;
  text-align: center;
  margin-bottom: 1em;
  transition: 0.2s all;
}
.user-block .footer {
  justify-content: center;
}
.footer .logo-area {
  height: 70%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.loading-screen {
  background: white;
  color: black;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .dashboard-app .btn-style {
    padding: 0.1rem 0rem;
    margin: 0 0.2rem;
  }
  .dashboard-app .btn-style p {
    font-size: 12px;
  }

  .dashboard-app .one-btn {
    /* padding: 8px; */
    margin-left: 5%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #9c9ab8;
  }
  .dashboard-app .one-btn .btn-name {
    font-size: 11px;
    letter-spacing: 0.8px;
    font-weight: 600;
  }
  .dashboard-app .one-btn .icon {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .dashboard-app .one-btn .iconify {
    font-size: 16px;
  }
  .dashboard-app .mobile-disp-container .mobile-disp {
    height: 65vh;
    width: 32vh;
  }
  .dashboard-app .mobile-disp-container iframe {
    height: 65vh;
    width: 32vh;
  }
}

@media only screen and (max-width: 800px) {
  .input-adornment {
    /* color: blue; */
    color: #6f6f79;
    font-size: 14px;
  }
  .dashboard-app .greet {
    display: none;
  }
  .dashboard-app .disp-title {
    -ms-grid-column: 2;
    -ms-grid-column-span: 5;
    grid-column: 2/7;
    border: none;
    border-bottom: 1px solid #9c9ab8;
    font-size: 16px;
  }
  .dashboard-app .disp-title .btn-style {
    margin: 0 1rem;
  }
  .dashboard-app .app-disp {
    display: none;
  }
  .dashboard-app .app-body {
    -ms-grid-column: 2;
    -ms-grid-column-span: 5;
    grid-column: 2/7;
    grid-row: 2/13;
  }
  .dashboard-app .app-body .title {
    font-size: 18px;
  }
  .dashboard-app .app-body .data-all {
    padding: 20px;
  }
  .dashboard-app .single-social-link {
    height: 65px;
    /* margin-bottom: 20px; */
  }
  .dashboard-app .single-social-link .icon {
    font-size: 25px;
  }
  .dashboard-app .nav-bar .nav-btn ul li .one-btn {
    /* display: -ms-grid; */
    display: grid;
    /* -ms-grid-columns: 1fr; */
    grid-template-columns: 1fr;
  }
  .one-btn .icon {
    grid-column: 1/3;
  }
  .dashboard-app .nav-bar .nav-btn ul li .one-btn .btn-name {
    display: none;
  }
  .dashboard-app .btn-style {
    padding: 0.2rem 0rem;
    margin: 0.1rem 0.2rem;
  }
  .dashboard-app .btn-style p {
    font-size: 12px;
  }
  .dashboard-app .nav-bar .profile-small {
    display: none;
  }
  .qr-code-container {
    grid-row: 2/13;
    grid-column: 2/7;
  }
}

@media only screen and (max-width: 500px) {
  .input-adornment {
    /* color: blue; */
    color: #6f6f79;
    font-size: 12px;
  }
  .dashboard-app {
    grid-template-rows: repeat(14, 1fr);
  }
  .dashboard-app .btn-style {
    padding: 0.4rem 0.15rem;
    margin: 0.1rem 0.1rem;
  }
  .dashboard-app .btn-style p {
    font-size: 11px;
  }
  .dashboard-app .nav-bar .nav-btn ul li .one-btn {
    margin: 0;
    border-radius: 0;
  }
  .dashboard-app .nav-bar {
    grid-column: 1/7;
    grid-row: 14/15;
    /* overflow: hidden; */
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
  .dashboard-app .nav-bar .logo {
    display: none;
  }
  .dashboard-app .nav-bar .nav-btn {
    grid-row: 1/2;
    grid-column: 1/6;
  }
  .dashboard-app .nav-bar .nav-btn ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }
  .dashboard-app .nav-bar .nav-btn ul li {
    padding: 0;
  }
  .dashboard-app .nav-bar .nav-btn ul li .one-btn a {
    padding: 0;
    border-radius: 0;
    margin: 0;
  }

  .dashboard-app .app-body {
    grid-column: 1/7;
    grid-row: 2/14;
  }
  .dashboard-app .disp-title {
    grid-column: 1/7;
    font-size: 18px;
    font-weight: 300;
  }
  .dashboard-app .app-body .data-all {
    padding: 10px;
  }
  .dashboard-app .app-body .data-all .data-select {
    grid-template-columns: 1fr 1fr;
  }
  .data-all .tile {
    margin: 1rem;
    height: 250px;
  }
  .dashboard-app .app-body .app-form .title {
    grid-template-columns: 1fr 100px;
    -ms-grid-columns: 1fr 100px;
  }
  .dashboard-app .single-social-link {
    -ms-grid-columns: 2fr 7fr;
    grid-template-columns: 2fr 7fr;
  }
  .dashboard-app .single-social-link input {
    font-size: 12px;
  }
  .data-custom-links .disp-custom .single-custom-link {
    display: grid;
    /* grid-template-columns: auto; */
    grid-template-rows: 1fr 1fr 1fr;
  }
  .single-custom-link .link-title {
    grid-row: 1/2;
    grid-column: 1/3;
  }

  .single-custom-link .link-url {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .single-custom-link .del-btn {
    grid-column: 1/3;
    grid-row: 3/4;
    justify-content: center;
  }

  .qr-code-container {
    grid-row: 2/15;
    grid-column: 1/7;
  }
}

/* //Alert color variables */
:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

/* //alert */
.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  width: 20em;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.alert-light {
  background: var(--light-color);
  color: #333;
}

.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.alert-success {
  background: var(--success-color);
  color: #fff;
}
/* Landing.js */

.get-started {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: white;
}
.get-started .nav-bar {
  /* border-bottom: 1px solid black; */
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
/* .get-started .profile-disp-container {
  height: 100%;
  width: 13em;
} */
.get-started .dropbtn {
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: black;
  padding: 0.5em;
  font-size: 16px;
  border: none;
  width: 13em;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 2.5fr 0.7fr;
}
.get-started .dropbtn .image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.get-started .dropbtn .profile-name {
  /* width: 90%; */
  text-align: left;
  padding-left: 0.7em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  font-size: 16px;
  font-weight: 600;
}
.get-started .dropdown {
  position: relative;
  display: inline-block;
}

.get-started .dropdown-content {
  display: none;
  width: 100%;
  position: absolute;
  background-color: white;
  min-width: 160px;
  /* box-shadow: 5px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.get-started .dropdown-content a {
  color: black;
  font-size: 12px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.get-started .dropdown-content a:hover {
  background-color: rgb(245, 245, 245);
}

.get-started .dropdown:hover .dropdown-content {
  display: block;
}

.get-started .dropdown:hover .dropbtn {
  background-color: #ffffff;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* CREATE.DESIGN.SHARE. */

.get-started .first-impressions {
  height: 80vh;
  display: grid;
  grid-template-rows: 3fr 1fr 1fr;
}
.get-started .first-impressions .create-design-share {
  display: grid;
  grid-template-columns: auto auto auto;
  /* width: 100%; */
  margin: 0 auto;
  font-weight: 900;
  font-size: 8vw;
}
.get-started .design-homepage {
  background: linear-gradient(-45deg, #6355a4, #6355a4, #e89a3e, #e89a3e);
  background-size: 300%;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-weight: 900;
  /* font-size: 5vw; */
  letter-spacing: -5px;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animated_text 5s ease-in-out infinite;
  -moz-animation: animated_text 5s ease-in-out infinite;
  -webkit-animation: animated_text 5s ease-in-out infinite;
}
.get-started .get-a-demo {
  background: black;
  border: none;
}

/* Text animation */

.heading-line {
  line-height: 1;
  display: block;
  position: relative;
  text-align: center;
}

.heading-line::before {
  line-height: 1;
  display: block;
  position: absolute;
  color: black;
  pointer-events: none;
  width: 100%;
}

.heading-line-gradient {
  -webkit-text-fill-color: transparent;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

/* 1st heading gradient */
@keyframes first_heading_gradient {
  0%,
  16.667% {
    opacity: 1;
  }

  33.333%,
  83.333% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* 1st heading white text */
@keyframes first_heading_white_text {
  0%,
  16.667% {
    opacity: 0;
  }

  25%,
  91.667% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes second_heading_gradient {
  0%,
  16.667% {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }

  66.667%,
  100% {
    opacity: 0;
  }
}

@keyframes second_heading_white_text {
  0%,
  25% {
    opacity: 1;
  }

  33.333%,
  50% {
    opacity: 0;
  }

  58.333%,
  100% {
    opacity: 1;
  }
}

@keyframes third_heading_gradient {
  0%,
  50% {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes third_heading_white_text {
  0%,
  58.333% {
    opacity: 1;
  }

  66.667%,
  83.333% {
    opacity: 0;
  }

  91.667%,
  100% {
    opacity: 1;
  }
}

.heading-line-first > .heading-line-gradient {
  background-image: linear-gradient(to left, #ed8936, #ed64a6);
  animation: first_heading_gradient 5s infinite;
}

.heading-line-first::before {
  content: 'Create.';
  animation: first_heading_white_text 5s infinite;
}

.heading-line-second > .heading-line-gradient {
  background-image: linear-gradient(to left, #38b2ac, #0bc5ea);
  animation: second_heading_gradient 5s infinite;
}

.heading-line-second::before {
  content: 'Design.';
  animation: second_heading_white_text 5s infinite;
}

.heading-line-third > .heading-line-gradient {
  background-image: linear-gradient(to left, #ecc94b, #48bb78);
  animation: third_heading_gradient 5s infinite;
}

.heading-line-third::before {
  content: 'Share.';
  animation: third_heading_white_text 5s infinite;
}
/* sad */

.get-started .first-impressions .sub-title-first-impression {
  text-align: center;
}
.get-started .first-impressions .first-impression-buttons {
  display: flex;

  justify-content: space-around;
}
.get-started .first-impressions .first-impression-buttons .links-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.get-started .first-impressions .first-impression-buttons .links-buttons div {
  text-align: center;
  padding: 1rem;
  /* border: 1px solid black; */
  border-radius: 10px;
  margin: 0 1rem;
  font-weight: 600;
}
.get-started .start-creating a {
  color: black;
}
.get-started .start-creating {
  align-items: center;
  box-shadow: black 2px 2px 10px;
}

.get-started .start-creating a:hover {
  color: black;
}
.get-started .get-a-demo a {
  color: white;
}
.get-started .get-a-demo a:hover {
  color: white;
}
@media (max-width: 960px) {
  .get-started .first-impressions .create-design-share {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    /* width: 100%; */
    margin: 0 auto;
    font-weight: 900;
    font-size: 9vw;
  }
}
@media (max-width: 700px) {
  .get-started .first-impressions .create-design-share {
    font-size: 12vw;
  }
}
@media (max-width: 550px) {
  .get-started .first-impressions .create-design-share {
    font-size: 19vw;
  }
}

/* .get-started .profile-disp-container .profile-disp {
  background-color: white;
  box-shadow: 2px 2px 5px #bebebe;
  border-radius: 10px;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 0.5fr;
  grid-template-columns: 1fr 1.75fr;
}

.get-started .profile-avatar {
  grid-row: 1/3;
}
.get-started .profile-avatar .image {
  height: 50px;
  width: 50px;
  margin: 0.3em 0;
  border-radius: 50%;
  background-color: #bc64d6;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.get-started .profile-name {
  font-size: 18px;
  font-weight: 600;
  height: 100%;
  margin: 0;
  display: grid;
  align-items: center;
}
.get-started .profile-name p {
  height: 100%;
  color: black;
  
}
.get-started .profile-logout {
  font-size: 13px;
  font-weight: 400;
} */
.get-started .log-btns {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.get-started .sign-btn {
  font-size: 18px;
  background-color: #0167ff;
  color: white;
  font-weight: 600;
  /* padding: 0.4em; */
  /* margin: 0.5em; */
  border: none;
  text-align: center;
  border-radius: 100px;
}

.get-started .sign-btn:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.get-started .sign-btn a {
  color: inherit;
  text-decoration: none;
}
.get-started .has-fade {
  visibility: hidden;
}

@-webkit-keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

.get-started .fade-in {
  -webkit-animation: fade-in 200ms ease-in-out forwards;
  animation: fade-in 200ms ease-in-out forwards;
}

@-webkit-keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}

.get-started .fade-out {
  -webkit-animation: fade-out 200ms ease-in-out forwards;
  animation: fade-out 200ms ease-in-out forwards;
}
@media (min-width: 64em) {
  body {
    font-size: 1.125rem;
  }
}

.get-started h1,
.get-started h2,
.get-started h3 {
  font-weight: 300;
  line-height: 1.15;
  color: #2d314d;
  margin-top: 0px;
}

.get-started h1 {
  font-size: 2.31rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 64em) {
  .get-started h1 {
    font-size: 3.25rem;
  }
}

.get-started h2 {
  font-size: 1.875rem;
  margin-bottom: 1.5625rem;
}

@media (min-width: 64em) {
  .get-started h2 {
    font-size: 2.25rem;
    margin-bottom: 2.25rem;
  }
}

.get-started p {
  line-height: 1.5;
  margin-bottom: 2.25rem;
}

.get-started a,
.get-started a:visited,
.get-started a:hover {
  text-decoration: none;
}

.get-started .container {
  max-width: 90vw;
  margin: 0 AUTO;
}

.get-started .container--pall-head {
  padding-top: 4.375rem;
  padding-right: 1.5rem;
  padding-bottom: 4.375rem;
  padding-left: 1.5rem;
}
.get-started .container--pall {
  padding-top: 4.375rem;
  padding-right: 1.5rem;
  padding-bottom: 4.375rem;
  padding-left: 1.5rem;
}

@media (min-width: 64em) {
  .get-started .container--pall {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.get-started .container--py {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}

@media (min-width: 64em) {
  .get-started .container--py {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.get-started .container--px {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.get-started .container--pt {
  padding-top: 4.375rem;
}

@media (min-width: 64em) {
  .get-started .container--pt {
    padding-top: 6rem;
  }
}

.get-started .container--pr {
  padding-right: 1.5rem;
}

.get-started .container--pb {
  padding-bottom: 4.375rem;
}

@media (min-width: 64em) {
  .get-started .container--pb {
    padding-bottom: 6rem;
  }
}

.get-started .container--pl {
  padding-left: 1.5rem;
}

.get-started .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.get-started .flex-jc-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.get-started .flex-jc-c {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.get-started .flex-ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.get-started .button,
.login .button {
  position: relative;
  display: inline-block;
  padding: 0.875rem 2.1875rem;
  background-color: black;
  font-weight: 600;
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fc30b6),
    to(#fe6f4e)
  );
  background-image: linear-gradient(to right, #fc30b6, #fe6f4e); */
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  /* font-weight: 400; */
  font-size: 0.875rem;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  overflow: hidden;
}

.get-started .button::before,
.login .button::before,
#sign-in-btn::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.25);
  opacity: 0;
  -webkit-transition: opacity 150ms ease-in-out;
  transition: opacity 150ms ease-in-out;
}

.get-started .button:hover::before,
.login .button:hover::before,
#sign-in-btn:hover::before {
  opacity: 1;
}
#sign-in-btn {
  position: relative;
  display: inline-block;
  padding: 0.875rem 2.1875rem;

  border: 0;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875rem;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  overflow: hidden;
  padding: 0.875rem 2.1875rem;
  background-color: white;
  color: rgb(43, 43, 43);
}

@media (max-width: 63.9375em) {
  .get-started .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 64em) {
  .get-started .hide-for-desktop {
    display: none;
  }
}

.get-started .header {
  position: relative;
  z-index: 1;
  width: 100vw;
  /* height: 13.5vh; */
  /* overflow: hidden; */
}

.get-started .header.open .header__toggle > span:first-child {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.get-started .header.open .header__toggle > span:nth-child(2) {
  opacity: 0;
}

.get-started .header.open .header__toggle > span:last-child {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.get-started .header .overlay {
  opacity: 0;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#2d314d),
    to(transparent)
  );
  background-image: linear-gradient(#2d314d, transparent);
}

.get-started .header nav {
  position: relative;
  background-color: white;
  padding-top: 1.0625rem;
  padding-bottom: 1.0625rem;
}

.get-started .header__logo img {
  width: 8.8125rem;
  height: 100%;
}

.get-started .header__toggle > span {
  display: block;
  width: 26px;
  height: 2px;
  background-color: #2d314d;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-transform-origin: 3px 1px;
  transform-origin: 3px 1px;
}

.get-started .header__toggle > span:not(:last-child) {
  margin-bottom: 5px;
}

.get-started .header__menu {
  position: absolute;
  width: calc(100% - 3rem);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: white;
  margin-top: 1.5rem;
  padding: 1.625rem;
  border-radius: 5px;
}

.get-started .header__menu a {
  display: block;
  padding: 0.625rem;
  color: #2d314d;
  text-align: center;
}

.get-started .header__links a {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9698a6;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}

.get-started .header__links a:not(:last-child) {
  margin-right: 32px;
}

.get-started .header__links a::before {
  content: '';
  display: block;
  position: absolute;
  height: 5px;
  left: 0;
  right: 0;
  bottom: -30px;
  /* background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fc30b6),
    to(#fe6f4e)
  ); */
  /* background: linear-gradient(to right, #fc30b6, #fe6f4e); */
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 150ms ease-in-out;
}

.get-started .header__links a:hover {
  color: #2d314d;
}

.get-started .header__links a:hover::before {
  opacity: 1;
}

.get-started .hero {
  background-color: white;
  width: 100vw;
  overflow-x: hidden;
}

@media (min-width: 64em) {
  .get-started .hero .container {
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.get-started .hero__image {
  position: relative;
  background-image: url('./images/big-intro-mobile-new.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 17.5rem;
}

@media (min-width: 40em) {
  .get-started .hero__image {
    min-height: 25rem;
    background-position: center 70%;
  }
}

@media (min-width: 64em) {
  .get-started .hero__image {
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    height: 41rem;
    background-image: none;
  }
}

@media (min-width: 64em) {
  .get-started .hero__image::before {
    content: '';
    position: absolute;
    height: 100%;
    background-image: url('./images/bg-intro-desktop-new.webp');
    background-repeat: no-repeat;
    background-position: center bottom;
    opacity: 1;
    width: 150%;
    background-size: 122%;
    background-position: 0% 83%;
  }
}

.get-started .hero__image::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('./images/mockups-3.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 40em) {
  .get-started .hero__image::after {
    background-size: 52%;
  }
}

@media (min-width: 64em) {
  .get-started .hero__image::after {
    width: 140%;
    height: 100%;
    background-size: 100%;
    background-position: center;
    left: 5%;
  }
}

.get-started .hero__text {
  text-align: center;
}
.get-started .hero__text h2 {
  font-weight: 800;
}

@media (min-width: 64em) {
  .get-started .hero__text {
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    text-align: left;
  }
}

.get-started .feature {
  /* background-color: #f3f4f6; */
  text-align: center;
  width: 100vw;
  overflow-x: hidden;
}

.get-started .feature__intro {
  text-align: center;
  /* margin-bottom: 3.75rem; */
  /* grid-column: 1/4; */
}

@media (min-width: 64em) {
  .get-started .feature__intro {
    /* width: 60%; */
  }
}

@media (min-width: 40em) {
  .get-started .feature__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.get-started .feature__item {
  padding: 0.9375rem;
  margin-bottom: 2rem;
}

@media (min-width: 40em) {
  .get-started .feature__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 64em) {
  .get-started .feature__item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

.get-started .feature__icon {
  margin-bottom: 1.875rem;
  justify-content: center;
}
.feature__icon img {
  width: 74px;
  height: 74px;
}

@media (min-width: 64em) {
  .get-started .feature__icon {
    margin-bottom: 2.75rem;
  }
}

.get-started .feature__title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.15;
  color: #2d314d;
  margin-bottom: 1rem;
  text-align: center;
}

@media (min-width: 64em) {
  .get-started .feature__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.get-started .feature__description {
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: center;
}
.get-started .create-dotcard {
  background-color: white;
  width: 100vw;
  overflow-x: hidden;
}
.get-started .create-dotcard .create-dotcard-container {
  margin: 1em 8em;
  margin-bottom: 3em;
  /* border: black solid 1px; */
  display: grid;
  grid-template-columns: 1fr 1.75fr;
}
.get-started .create-dotcard-container .title {
  text-align: center;
  grid-column: 1/3;
  margin: 2em 0;
}
.get-started .create-dotcard-container .title-header {
  font-weight: 600;
  margin-top: 0.5em;
}
.get-started .minor-card {
  color: #1f2a82;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 0.75rem;
  background-color: white;
}
.get-started .create-dotcard-container .steps {
  margin: 2em 0;
}
.get-started .create-dotcard-container .steps .step {
  /* border: 1px solid black; */
  padding: 2.5em;
  margin: 0 4em;
  margin-bottom: 2em;
  border-radius: 7px;
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 15px;
}
.get-started .create-dotcard-container .mockup-disp img {
  height: 600px;
  width: auto;
}
.get-started .step-non-highlight {
  transition: 0.5s all;
}
.get-started .step-non-highlight:hover {
  background-color: #9bbcff36;
}
.get-started .step-highlight {
  background-color: white;
  box-shadow: 0 6px 12px 0 rgb(48 54 77 / 12%);
}
.get-started .step .step-icon {
  margin-right: 1.5em;
}
.get-started .step .step-icon img {
  width: 60px;
  height: 60px;
}
.get-started .step .step-content {
  padding-right: 2em;
}
.get-started .step .step-content .step-head {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 0.7;
}
.get-started .step .step-content .step-details {
  line-height: 1.5;
}
@media only screen and (max-width: 1200px) {
  .get-started .create-dotcard .create-dotcard-container {
    margin: 1em 4em;
    margin-bottom: 3em;
    /* border: black solid 1px; */
    display: grid;
    grid-template-columns: 1fr 1.75fr;
  }

  .get-started .create-dotcard-container .steps .step {
    /* border: 1px solid black; */
    padding: 2em;
    margin: 0 3em;
    margin-bottom: 2em;
    border-radius: 7px;
    display: grid;
    grid-template-columns: auto 1fr;
    font-size: 15px;
  }

  .get-started .step .step-icon {
    margin-right: 1.5em;
  }
  .get-started .step .step-icon img {
    width: 50px;
    height: 50px;
  }
  .get-started .step .step-content {
    padding-right: 1em;
  }
  .get-started .step .step-content .step-head {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 0.7;
  }
}
@media only screen and (max-width: 900px) {
  .get-started .create-dotcard .create-dotcard-container {
    margin: 1em 1em;
    margin-bottom: 0em;
    /* border: black solid 1px; */
    display: grid;
    /* grid-template-columns: 1fr; */
    grid-template-rows: auto auto 1fr;
  }
  .get-started .create-dotcard .create-dotcard-container .mockup-disp {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .get-started .create-dotcard .create-dotcard-container .steps {
    grid-column: 1/3;
    grid-row: 3/4;
  }

  .get-started .create-dotcard-container .steps .step {
    /* border: 1px solid black; */
    padding: 1.75em;
    margin: 0 3em;
    margin-bottom: 2em;
    border-radius: 7px;
    display: grid;
    grid-template-columns: auto 1fr;
    font-size: 14px;
  }

  .get-started .step .step-icon {
    margin-right: 1.5em;
  }
  .get-started .step .step-icon img {
    width: 50px;
    height: 50px;
  }
  .get-started .step .step-content {
    padding-right: 1em;
  }
  .get-started .step .step-content .step-head {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 0.7;
  }
}
@media only screen and (max-width: 450px) {
  .get-started .create-dotcard .create-dotcard-container {
    margin: 1em 1em;
    margin-bottom: 0em;
    /* border: black solid 1px; */
    display: grid;
    /* grid-template-columns: 1fr; */
    grid-template-rows: auto auto 1.25fr;
  }
  .get-started .create-dotcard .create-dotcard-container .mockup-disp {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .get-started .create-dotcard .create-dotcard-container .steps {
    grid-column: 1/3;
    grid-row: 3/4;
  }
  .get-started .create-dotcard-container .mockup-disp img {
    height: 450px;
    width: auto;
  }

  .get-started .create-dotcard-container .steps .step {
    /* border: 1px solid black; */
    padding: 1.75em 1em;
    margin: 0 3em;
    margin-bottom: 1em;
    border-radius: 7px;
    display: grid;
    grid-template-columns: auto 1fr;
    font-size: 13px;
  }
  .get-started .step .step-icon {
    margin-right: 1.5em;
  }
  .get-started .step .step-icon img {
    width: 50px;
    height: 50px;
  }
  .get-started .step .step-content {
    padding-right: 1em;
  }
  .get-started .step .step-content .step-head {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 0.7;
  }
}

.get-started .about-section {
  background-color: white;
  width: 100vw;
  height: 100vh;
}

.get-started .articles {
  background-color: white;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  padding: 4em 10em;
  background-image: url('../dist/images/undraw_contact_us_15o2.svg');
  background-position: center;
  background-size: cover;
}
.get-started .contact-form-container {
  /* border: 1px solid black; */
  height: 100%;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.281);
}

.get-started .contact-form-container .left-side {
  /* background-color: lightpink; */
  border-radius: 10px 0 0 10px;
}
.get-started .contact-form-container .right-side {
  background: rgba(241, 241, 241, 0.781);
  padding: 0 4em;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 1fr 6fr;
}
.get-started .contact-form-container .title {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 4px;
}
.get-started .contact-form {
  /* padding: 4em;
  padding-top: 2em; */
  padding: 0 2em;
  height: 100%;
}
.get-started .contact-form form {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 3fr 1fr;
}
@media only screen and (max-width: 500px) {
  .get-started .articles {
    background-color: white;
    width: 100vw;
    height: 70vh;
    height: calc(var(--vh, 1vh) * 70);
    overflow-x: hidden;
    padding: 2em 1em;
  }
  .get-started .contact-form-container .title {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1.5px;
  }
  .get-started .contact-form {
    padding: 0em;
    height: 100%;
  }
  .get-started .contact-form-container .right-side {
    padding: 0 2em;
  }
}
.get-started .footer-try {
  padding-top: 3em;
  display: grid;
}
.get-started .footer-try .only-line {
  padding: 0em 2em;
  padding-bottom: 3em;
}
.get-started .footer-try .follow-section {
  background-color: white;
  /* margin: 0 2em; */
  padding: 2em;
}
.get-started .footer-try .back-to-top {
  height: 3em;
  font-weight: 600;
  font-size: 1.2rem;
}
.get-started .footer-home-container {
  padding: 3% 15%;
  padding-bottom: 0%;
  background-color: #15141f;
  color: white;
}
.get-started .footer-home {
  display: grid;
  grid-template-rows: 1fr 0.5fr 0.2fr;
}
.get-started .footer-try .logo-footer img {
  margin-bottom: 1em;
  /* height: 3em; */
  width: auto;
}
.get-started .footer-try .social-icons {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  font-size: 0.8em;
  line-height: 3;
  /* flex-direction: row; */
  /* justify-content: space-evenly; */
  text-transform: uppercase;
}
.get-started .footer-try .social-icons span {
  margin: 0 1em;
}
.get-started .footer-try .social-icons span:hover {
  /* font-weight: 800; */
  color: rgb(70, 70, 70);
  text-decoration: underline;
}

.get-started .footer-try .copyright {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: 600;
  /* font-variant-caps: titling-caps; */
  text-transform: capitalize;
}
.get-started .footer-try .copyright > span {
  display: inline;
}

.get-started .video-mockup-container {
  width: 100vw;
  overflow-x: hidden;
  height: auto;
  position: relative;
  /* width: 100%; */
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.get-started .video-mockup-container video {
  width: 100vw;
}

.get-started .video-mockup-container .overlay-prevent-touch {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
}
